import React, {useState} from 'react';
import {
    ArrowDown, ArrowDownward, HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg
} from "./HeroElements";
import Video from '../../video/video.mp4';
import {ButtonScroll} from "../ButtonElements";

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    return (<HeroContainer>
        <HeroBg>
            <VideoBg autoPlay loop muted playsInline src={Video} type='video/mp4'/>
        </HeroBg>
        <HeroContent>
            <HeroH1>Portland Projects</HeroH1>
            <HeroP>Contact us now for a free quote!</HeroP>
            <HeroBtnWrapper>
                <ButtonScroll
                    smooth={true}
                    dureation={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    to="contact"
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    primary="true"
                    dark="true">
                    Contact Now {hover ? <ArrowDownward/> : <ArrowDown/>}
                </ButtonScroll>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>);
};

export default HeroSection;