import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${({background}) => (background ? '#010606' : 'transparent')};
  transition: all 0.2s ease-in-out;

  @media (max-width: 812px) {
    transition: 0.8s all ease;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  padding: 0 1.5rem;
  max-width: 1100px;
  margin-bottom: 1rem;

  @media (max-width: 812px) {
    margin-bottom: 0;
  }
`

export const NavLogo = styled(LinkR)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  text-decoration: none;
`

export const Logo = styled.img`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 48px;
`

export const NavContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem 0;
`

export const NavContact = styled.div`
  padding: 0 1rem;

  @media (max-width: 812px) {
    display: none;
  }
`

export const NavMobileLink = styled.a`
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  text-decoration: none;
  display: block;
`

export const NavContactEmail = styled.a`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const NavEmail = styled.span`
  margin-left: 0.25rem;
`

export const MobileIcon = styled.div`
  display: none;

  @media (max-width: 812px) {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: 1.5rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: ${({background}) => (background ? '#010606' : '#fff')};
    background: ${({background}) => (background ? '#fff' : '#010606')};
  }
`

export const NavMenu = styled.ul`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  padding: 1rem 1rem;
  border-radius: 8px;
  background: #010606;

  @media (max-width: 812px) {
    display: none;
  }
`

export const NavLinks = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #010606;
  }
`

export const NavLinksScroll = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #010606;
  }
`