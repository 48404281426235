import React from 'react';
import SimpleGallery from "../components/SimpleGallery";

const Residential = () => {
    return (<SimpleGallery
        lightBg={true}
        lightText={false}
        topLine='Services'
        headLine='Residential'
        description='<ul><li>Granny Flats</li><li>Duplex</li><li>Extensions</li></ul>'
        darkText={true}
        galleryID="residential"
        images={[{
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075878/portlandprojects/residential/277613337_1127296718003906_4080415317063743409_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075878/portlandprojects/residential/277613337_1127296718003906_4080415317063743409_n.jpg',
            width: 2048,
            height: 1536,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075877/portlandprojects/residential/277613910_391873879136109_8871984559456650468_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075877/portlandprojects/residential/277613910_391873879136109_8871984559456650468_n.jpg',
            width: 1124,
            height: 1500,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075877/portlandprojects/residential/277251470_987415038584872_8399564536790134702_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075877/portlandprojects/residential/277251470_987415038584872_8399564536790134702_n.jpg',
            width: 1536,
            height: 2048,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075877/portlandprojects/residential/277597993_737531573908211_2111053425645064158_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075877/portlandprojects/residential/277597993_737531573908211_2111053425645064158_n.jpg',
            width: 1124,
            height: 1500,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075877/portlandprojects/residential/277834848_400943262033562_4163354489006457729_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075877/portlandprojects/residential/277834848_400943262033562_4163354489006457729_n.jpg',
            width: 1200,
            height: 1600,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075877/portlandprojects/residential/277407927_966928697343805_4496911184717903521_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075877/portlandprojects/residential/277407927_966928697343805_4496911184717903521_n.jpg',
            width: 1536,
            height: 2048,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075876/portlandprojects/residential/273077201_5201645036566328_8936252545262044536_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075876/portlandprojects/residential/273077201_5201645036566328_8936252545262044536_n.jpg',
            width: 1024,
            height: 768,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075876/portlandprojects/residential/277700829_501464828355713_7099019741743159586_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075876/portlandprojects/residential/277700829_501464828355713_7099019741743159586_n.jpg',
            width: 1024,
            height: 768,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075876/portlandprojects/residential/277687694_1124530928090260_6096713150579915284_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075876/portlandprojects/residential/277687694_1124530928090260_6096713150579915284_n.jpg',
            width: 1200,
            height: 1600,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075876/portlandprojects/residential/277853008_468636158334398_6267582867935543974_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075876/portlandprojects/residential/277853008_468636158334398_6267582867935543974_n.jpg',
            width: 2048,
            height: 1536,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075875/portlandprojects/residential/277455342_362986562418131_7376611575964315053_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075875/portlandprojects/residential/277455342_362986562418131_7376611575964315053_n.jpg',
            width: 768,
            height: 1024,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075875/portlandprojects/residential/277716048_661775938383344_6524241472113973320_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075875/portlandprojects/residential/277716048_661775938383344_6524241472113973320_n.jpg',
            width: 1125,
            height: 842,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075875/portlandprojects/residential/277823059_3173021549690783_5671435614626462572_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075875/portlandprojects/residential/277823059_3173021549690783_5671435614626462572_n.jpg',
            width: 1600,
            height: 1200,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075875/portlandprojects/residential/277542467_501765741535342_3008128317233930211_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075875/portlandprojects/residential/277542467_501765741535342_3008128317233930211_n.jpg',
            width: 2048,
            height: 1536,
        }, {
            largeURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075874/portlandprojects/residential/277359813_558759375344361_5967850247147836928_n.jpg',
            thumbnailURL: 'https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075874/portlandprojects/residential/277359813_558759375344361_5967850247147836928_n.jpg',
            width: 1536,
            height: 2048,
        }]}/>);
};

export default Residential;