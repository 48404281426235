import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;
`

export const ServicesCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin: 0 0.5rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 812px) {
    grid-template-columns: 1fr;
  }
`

export const ServicesCard = styled(LinkR)`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 2rem;
  margin: 0 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #010606;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media (max-width: 992px) {
    margin-bottom: 1rem;
  }
`

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 544px) {
    font-size: 2rem;
  }
`

export const ServicesH2 = styled.h2`
  font-size: 1.25rem;
`

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
`