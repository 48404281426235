import React from 'react';
import {
    CloseIcon,
    Icon,
    SidebarContainer,
    SidebarLink, SidebarLinkScroll,
    SidebarMenu,
    SidebarWrapper,
} from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink onClick={toggle} to='/'>Home</SidebarLink>
                    <SidebarLink onClick={toggle} to='about-us'>About Us</SidebarLink>
                    <SidebarLink onClick={toggle} to='civil'>Civil</SidebarLink>
                    <SidebarLink onClick={toggle} to='high-rise'>High Rise</SidebarLink>
                    <SidebarLink onClick={toggle} to='residential'>Residential</SidebarLink>
                    <SidebarLinkScroll smooth={true}
                                       dureation={500}
                                       spy={true}
                                       exact="true"
                                       offset={-80}
                                       onClick={toggle} to='contact'>Contact Us</SidebarLinkScroll>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>);
};

export default Sidebar;