import React from 'react';
import {
    ServicesCard, ServicesCards,
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesIcon,
} from "./ServicesElements";
import Icon1 from '../../images/undraw_under_construction_-46-pa.svg'
import Icon2 from '../../images/undraw_city_life_gnpr.svg'
import Icon3 from '../../images/svg-1.svg'
import {Wrapper} from "../PageElements";

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <Wrapper>
            <ServicesH1>Our Services</ServicesH1>
                <ServicesCards>
                    <ServicesCard to="/civil">
                        <ServicesIcon src={Icon1}/>
                        <ServicesH2>Civil</ServicesH2>
                    </ServicesCard>
                    <ServicesCard to="/high-rise">
                        <ServicesIcon src={Icon2}/>
                        <ServicesH2>High Rise</ServicesH2>
                    </ServicesCard>
                    <ServicesCard to="/residential">
                        <ServicesIcon src={Icon3}/>
                        <ServicesH2>Residential</ServicesH2>
                    </ServicesCard>
                </ServicesCards>
            </Wrapper>
        </ServicesContainer>
    );
};

export default Services;