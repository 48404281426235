import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.scss';

import {Body, Card, Container, Heading, HeadingSub, TopLine, Wrapper} from "../PageElements";
import {
    ContactUsCardForm,
    ContactUsCardInfo,
    ContactUsEmail, ContactUsEmailLink,
    ContactUsPhone,
    ContactUsPhoneLink
} from "./ContactUsElements";
import {FiMail, FiPhone} from "react-icons/fi";

const ContactUsForm = () => {
    const [formClass, setFormClass] = useState('');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setFormClass("form_pending");

        emailjs.sendForm('default_service', 'template_t93f1ke', form.current, 'user_ulSSD8e5alRmwFFnVA7Dw')
            .then((result) => {
                setFormClass("form_success");
                console.log(result);
            }, (error) => {
                setFormClass("form_fail");
                console.log(error);
            });
    };
    return (
        <Container id='contact'>
            <Wrapper>
                <TopLine className='u-text-center'>Contact Us</TopLine>
                <Heading className='u-text-center' lightText={true}>Get In Touch</Heading>
                <Body className='u-text-center'>
                    Any questions or remarks? Just write us a message!
                </Body>
                <Card className="mt-6 p-2">
                    <ContactUsCardInfo>
                        <HeadingSub lightText={true}>Contact Information</HeadingSub>
                        <Body>
                            Fill out the form and our team will get back to you as soon as possible.
                        </Body>
                        <ContactUsPhone href='tel:0410261997'>
                            <FiPhone/>
                            <ContactUsPhoneLink>
                                0410 261 997
                            </ContactUsPhoneLink>
                        </ContactUsPhone>
                        <ContactUsEmail href="mailto:info@portlandprojects.net.au">
                            <FiMail/>
                            <ContactUsEmailLink>
                                info@portlandprojects.net.au
                            </ContactUsEmailLink>
                        </ContactUsEmail>
                    </ContactUsCardInfo>
                    <ContactUsCardForm>
                        <form ref={form} onSubmit={sendEmail} className={`form ${formClass}`}>
                            <div className="pending">
                                <div className="spinner"/>
                            </div>
                            <div className="success">
                                <div className="message u-align--center">
                                    <i className="icon icon--medium icon--success fas fa-check-circle"/>
                                    <h3 className="mt-4 mb-2"> Thank you for contacting us!</h3>
                                    <div> We will reply to you as soon as possible.</div>
                                </div>
                            </div>
                            <div className="fail">
                                <div className="message u-align--center">
                                    <i className="icon icon--medium icon--fail fas fa-times-circle"/>
                                    <h3 className="mt-4 mb-2"> Something went wrong...</h3>
                                    <div className="link" onClick={() => setFormClass('')}>Please try again</div>
                                </div>
                            </div>
                            <div className="inputs">
                                <div className="input-container">
                                    <label>First Name</label>
                                    <input name="firstname" required type="text"/>
                                    <span className="input-focus"/>
                                </div>
                                <div className="input-container">
                                    <label>Last Name</label>
                                    <input name="lastname" type="text"/>
                                    <span className="input-focus"/>
                                </div>
                                <div className="input-container">
                                    <label>Email</label>
                                    <input name="email" required type="email"/>
                                    <span className="input-focus"/>
                                </div>
                                <div className="input-container">
                                    <label>Phone</label>
                                    <input name="phone" required type="tel"/>
                                    <span className="input-focus"/>
                                </div>
                                <div className="input-container full">
                                    <label>Message</label>
                                    <textarea name="message"/>
                                    <span className="input-focus"/>
                                </div>
                                <div className="m-6 input-container-button">
                                    <button type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </ContactUsCardForm>
                </Card>
            </Wrapper>
        </Container>
    );
};

export default ContactUsForm;