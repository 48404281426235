import React from 'react';
import {aboutUs} from "../components/InfoSection/Data";
import InfoSection from "../components/InfoSection";

const About = () => {
    return (
        <>
            <InfoSection {...aboutUs}/>
        </>
    );
};

export default About;