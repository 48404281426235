import styled from "styled-components";
import {Link} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll'

export const Button = styled(Link)`
  border-radius: 0.5rem;
  text-decoration: none;
  background: ${({primary}) => (primary ? '#bf872d' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#010606' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: 1px solid ${({primary}) => (primary ? '#bf872d' : '#010606')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#ffffff')};
    color: #010606;
  }
`

export const ButtonScroll = styled(LinkS)`
  border-radius: 0.5rem;
  text-decoration: none;
  background: ${({primary}) => (primary ? '#bf872d' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#010606' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: 1px solid ${({primary}) => (primary ? '#bf872d' : '#010606')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#ffffff')};
    color: #010606;
  }
`