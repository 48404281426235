import './App.scss';
import React, {useState} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Civil from "./pages/Civil";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import ContactUsForm from "./components/ContactUsForm";
import HighRise from "./pages/HighRise";
import Residential from "./pages/Residential";

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return (
        <Router forceRefresh={true}>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <ScrollToTop>
                <Routes>
                    <Route path='/' element={<Home/>} exact/>
                    <Route path='/about-us' element={<About/>} exact/>
                    <Route path='/civil' element={<Civil/>} exact/>
                    <Route path='/high-rise' element={<HighRise/>} exact/>
                    <Route path='/residential' element={<Residential/>} exact/>
                </Routes>
                <ContactUsForm/>
            </ScrollToTop>
        </Router>);
}

export default App;
