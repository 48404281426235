import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};
`

export const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  padding: 5rem 1.5rem;
  justify-content: center;
`

export const Heading = styled.h1`
  margin-bottom: 1.5rem;
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

  @media (max-width: 544px) {
    font-size: 32px;
  }
`

export const HeadingSub = styled.h1`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

  @media (max-width: 544px) {
    font-size: 32px;
  }
`

export const Body = styled.p`
  font-size: 1rem;
  line-height: 24px;
  white-space: break-spaces;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const TextWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 3.75rem;
`

export const TopLine = styled.p`
  color: #bf872d;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Card = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: 40% 60%;
  border-radius: 10px;
  padding: 2rem;
  margin: 0 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  @media (max-width: 812px) {
    grid-template-columns: 100%;
  }
`