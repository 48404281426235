import React, {useEffect, useState} from 'react';
import {FiMail} from 'react-icons/fi'
import LogoImg from '../../images/logo-small.png'
import {useLocation} from "react-router-dom";
import {
    Logo,
    MobileIcon,
    Nav,
    NavbarContainer,
    NavContact,
    NavContactContainer,
    NavContactEmail,
    NavEmail,
    NavLinks, NavLinksScroll,
    NavLogo,
    NavMenu,
    NavMobileLink
} from "./NavbarElements";

const Navbar = ({toggle}) => {
    const location = useLocation();

    const [offset, setOffset] = useState(0);
    const [homePage, setHomePage] = useState(true);

    useEffect(() => {
        //Homepage check
        if (window.location.pathname === '/') {
            setHomePage(true);
            setOffset(window.pageYOffset);
            const onScroll = () => setOffset(window.pageYOffset);
            window.removeEventListener('scroll', onScroll);
            window.addEventListener('scroll', onScroll, {passive: true});
            return () => window.removeEventListener('scroll', onScroll);
        } else {
            setHomePage(false);
            setOffset(200);
        }
    }, [location]);

    return (<Nav homePage={homePage}
                 background={offset ? offset > 180 : false}>
        <NavContactContainer>
            <NavLogo to='/'>
                <Logo src={LogoImg}/>
            </NavLogo>
            <NavContact>
                <NavMobileLink href='tel:0410261997'>
                    0410 261 997
                </NavMobileLink>
                <NavContactEmail href="mailto:info@portlandprojects.net.au">
                    <FiMail/>
                    <NavEmail>
                        info@portlandprojects.net.au
                    </NavEmail>
                </NavContactEmail>
            </NavContact>
        </NavContactContainer>
        <NavbarContainer>
            <MobileIcon onClick={toggle} background={offset ? offset > 180 : false}>
                Menu
            </MobileIcon>
            <NavMenu>
                <NavLinks to='about-us'>About Us</NavLinks>
                <NavLinks to='civil'>Civil</NavLinks>
                <NavLinks to='high-rise'>High Rise</NavLinks>
                <NavLinks to='residential'>Residential</NavLinks>
                <NavLinksScroll smooth={true}
                          dureation={500}
                          spy={true}
                          exact="true"
                          offset={-80}
                          to='contact'>Contact Us</NavLinksScroll>
            </NavMenu>
        </NavbarContainer>
    </Nav>);
};

export default Navbar;