export const aboutUsHome = {
    id: 'about',
    lightBg: true,
    lightText: false,
    topLine: 'About Us',
    headLine: 'Portland Projects',
    description: '<p>Portland Projects has built a reputation for excellence and outstanding performance.</p><p>Our project team maintains a strong foundation of trust and mutual respect generated through building positive relationships with clients, architects, engineers, subcontractors and suppliers ensuring the highest degree of professional service and results on all projects undertaken.</p>',
    imgStart: false,
    img: require('./../../images/undraw_team_re_0bfe.svg').default,
    alt: 'Car',
    buttonTo:'about-us',
    buttonLabel: 'Learn More',
    primary: false,
    dark: false,
    darkText: true,
}

export const aboutUs = {
    id: 'about',
    lightBg: true,
    lightText: false,
    darkText: true,
    topLine: 'About Us',
    headLine: 'Portland Projects',
    description: '<p>Portland Projects has built a reputation for excellence and outstanding performance.</p><p>Our project team maintains a strong foundation of trust and mutual respect generated through building positive relationships with clients, architects, engineers, subcontractors and suppliers ensuring the highest degree of professional service and results on all projects undertaken.</p><p>Additionally Portland Projects management services provide assurance to the owner of a well-run, problem-free job site. The team of experienced construction professionals provides cost control, scheduling coordination, site specific safety management planning, quality control and timely execution of plans throughout the project.</p><p>Our comprehensive knowledge of the construction process and our team approach is critical to the project’s on-time and on-budget completion according to plan. The owner’s interest is represented throughout the project, from start to finish, to optimize the owner’s investment in the project.</p>',
    imgStart: false,
    img: require('./../../images/undraw_team_re_0bfe.svg').default,
    alt: 'Car',
}