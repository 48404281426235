import React from 'react';
import {Button} from "../ButtonElements";
import {
    BtnWrap,
    Column1,
    Column2,
    Img,
    ImgWrap,
    InfoContainer,
    InfoRow,
} from "./InfoElements";
import {Body, Heading, TextWrapper, TopLine, Wrapper} from "../PageElements";

const InfoSection = ({
                         id,
                         lightBg,
                         lightText,
                         topLine,
                         headLine,
                         description,
                         buttonLabel,
                         imgStart,
                         img,
                         alt,
                         dark,
                         dark2,
                         primary,
                         buttonTo,
                         darkText
                     }) => {
    return (<>
        <InfoContainer lightBg={lightBg} id={id}>
            <Wrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headLine}</Heading>
                            <Body darkText={darkText} dangerouslySetInnerHTML={{__html: `${description}`}}/>
                            {buttonLabel ? <BtnWrap>
                                <Button to={buttonTo}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                >
                                    {buttonLabel}
                                </Button>
                            </BtnWrap> : null}
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </Wrapper>
        </InfoContainer>
    </>);
};

export default InfoSection;