import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};
`

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: ${({imgStart}) => (imgStart ? '45% 55%' : '55% 45%')};;
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media (max-width: 812px) {
    grid-template-columns: auto;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
  }
`

export const Column1 = styled.div`
  padding: 0 1rem;
  grid-area: col1;
`

export const Column2 = styled.div`
  padding: 0 1rem;
  grid-area: col2;
`

export const BtnWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: flex-start;
`

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`