import React from 'react';
import SimpleGallery from "../components/SimpleGallery";

const Civil = () => {
    return (
        <SimpleGallery
            lightBg={true}
            lightText={false}
            topLine='Services'
            headLine='Civil'
            description='<ul><li>Earth-moving, compaction and leveling</li><li>Detailed Excavation</li><li>Steel Fixing</li><li>Form-work</li><li>Concreting</li></ul>'
            darkText={true}
            galleryID="civil"
            images={[
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075871/portlandprojects/civil/277595400_262290212777812_1164357521591634524_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075871/portlandprojects/civil/277595400_262290212777812_1164357521591634524_n.jpg",
                    width: 2048,
                    height: 1536
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075871/portlandprojects/civil/277357886_304444585132066_4272946654074817947_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075871/portlandprojects/civil/277357886_304444585132066_4272946654074817947_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075870/portlandprojects/civil/277831675_516538696536289_4035641573067626866_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075870/portlandprojects/civil/277831675_516538696536289_4035641573067626866_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075869/portlandprojects/civil/277681652_1370405530145181_1847785121721081046_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075869/portlandprojects/civil/277681652_1370405530145181_1847785121721081046_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075868/portlandprojects/civil/277592964_4776018919192623_1262522376312975932_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075868/portlandprojects/civil/277592964_4776018919192623_1262522376312975932_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075868/portlandprojects/civil/277478391_766294170943211_4725322065196691402_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075868/portlandprojects/civil/277478391_766294170943211_4725322065196691402_n.jpg",
                    width: 2048,
                    height: 1536
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075867/portlandprojects/civil/277507492_1013347219588622_1236949258979334106_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075867/portlandprojects/civil/277507492_1013347219588622_1236949258979334106_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075867/portlandprojects/civil/277686195_801020400863774_8427258383551720969_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075867/portlandprojects/civil/277686195_801020400863774_8427258383551720969_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075867/portlandprojects/civil/277589971_1519341475128235_7124451625803481121_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075867/portlandprojects/civil/277589971_1519341475128235_7124451625803481121_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075866/portlandprojects/civil/277407003_5179484125444646_7944366501553070622_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075866/portlandprojects/civil/277407003_5179484125444646_7944366501553070622_n.jpg",
                    width: 2048,
                    height: 1536
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075865/portlandprojects/civil/277455336_3219348431633251_8097123775760261620_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075865/portlandprojects/civil/277455336_3219348431633251_8097123775760261620_n.jpg",
                    width: 2048,
                    height: 1536
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075865/portlandprojects/civil/277251470_987415038584872_8399564536790134702_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075865/portlandprojects/civil/277251470_987415038584872_8399564536790134702_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075864/portlandprojects/civil/277592610_495774352204991_7519010533336577386_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075864/portlandprojects/civil/277592610_495774352204991_7519010533336577386_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075864/portlandprojects/civil/277470928_465640328585082_5528512995589833637_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075864/portlandprojects/civil/277470928_465640328585082_5528512995589833637_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075864/portlandprojects/civil/Screen_Shot_2022-03-14_at_4.52.10_pm.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075864/portlandprojects/civil/Screen_Shot_2022-03-14_at_4.52.10_pm.jpg",
                    width: 899,
                    height: 1599
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075863/portlandprojects/civil/277608105_1275898539486746_8188915603720824613_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075863/portlandprojects/civil/277608105_1275898539486746_8188915603720824613_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075863/portlandprojects/civil/277344894_1082161932366480_7391167534325992962_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075863/portlandprojects/civil/277344894_1082161932366480_7391167534325992962_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075862/portlandprojects/civil/277370471_476030257590645_1287679713556051354_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075862/portlandprojects/civil/277370471_476030257590645_1287679713556051354_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075862/portlandprojects/civil/277610535_554401295855352_7805106024147796878_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075862/portlandprojects/civil/277610535_554401295855352_7805106024147796878_n.jpg",
                    width: 2048,
                    height: 1536
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075861/portlandprojects/civil/277406994_394212645542593_156721993889631004_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075861/portlandprojects/civil/277406994_394212645542593_156721993889631004_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075861/portlandprojects/civil/277682402_4909165069205680_540777944416984611_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075861/portlandprojects/civil/277682402_4909165069205680_540777944416984611_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075861/portlandprojects/civil/277719658_2507737159368143_3845029008572214016_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075861/portlandprojects/civil/277719658_2507737159368143_3845029008572214016_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075860/portlandprojects/civil/277421230_554017552573579_8955194247136407122_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075860/portlandprojects/civil/277421230_554017552573579_8955194247136407122_n.jpg",
                    width: 2048,
                    height: 1083
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075860/portlandprojects/civil/277591087_1127099784522232_8498919063043076985_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075860/portlandprojects/civil/277591087_1127099784522232_8498919063043076985_n.jpg",
                    width: 1536,
                    height: 2048
                }
            ]}
        />
    );
};

export default Civil;