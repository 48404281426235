import React from 'react';
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import {aboutUsHome} from "../components/InfoSection/Data";
import Services from "../components/Services";

const Home = () => {

    return (
        <>
            <HeroSection/>
            <Services/>
            <InfoSection {...aboutUsHome}/>
        </>
    );
};

export default Home;