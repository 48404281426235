import React from 'react';
import SimpleGallery from "../components/SimpleGallery";

const HighRise = () => {
    return (
        <SimpleGallery
            lightBg={true}
            lightText={false}
            topLine='Services'
            headLine='High Rise'
            description='<ul><li>Comprehensive defect inspections and repairs</li><li>Maintenance</li><li>Site supervisor/foreman</li></ul>'
            darkText={true}
            galleryID="highrise"
            images={[
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075875/portlandprojects/highrise/277613362_964035830919767_8139750634230775821_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075875/portlandprojects/highrise/277613362_964035830919767_8139750634230775821_n.jpg",
                    width: 1152,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075875/portlandprojects/highrise/277347388_480340463817440_85002238217620265_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075875/portlandprojects/highrise/277347388_480340463817440_85002238217620265_n.jpg",
                    width: 2048,
                    height: 1024
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075874/portlandprojects/highrise/277461118_5242400782469533_8389248482474507689_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075874/portlandprojects/highrise/277461118_5242400782469533_8389248482474507689_n.jpg",
                    width: 1152,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075874/portlandprojects/highrise/277541017_551017959603955_2875332152983852332_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075874/portlandprojects/highrise/277541017_551017959603955_2875332152983852332_n.jpg",
                    width: 1284,
                    height: 1712
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075874/portlandprojects/highrise/277548837_692641535262061_6906072184205527365_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075874/portlandprojects/highrise/277548837_692641535262061_6906072184205527365_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075873/portlandprojects/highrise/277298949_655479269075644_7711160774266689507_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075873/portlandprojects/highrise/277298949_655479269075644_7711160774266689507_n.jpg",
                    width: 1284,
                    height: 963
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075873/portlandprojects/highrise/277566939_5212877588762305_3515324900329135852_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075873/portlandprojects/highrise/277566939_5212877588762305_3515324900329135852_n.jpg",
                    width: 1284,
                    height: 963
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075872/portlandprojects/highrise/277684310_515095559994671_2813303733813697400_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075872/portlandprojects/highrise/277684310_515095559994671_2813303733813697400_n.jpg",
                    width: 1284,
                    height: 963
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075872/portlandprojects/highrise/276022548_1128323448001937_2273351626416142506_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075872/portlandprojects/highrise/276022548_1128323448001937_2273351626416142506_n.jpg",
                    width: 1536,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075872/portlandprojects/highrise/277683076_455472753038333_8839849004186485525_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075872/portlandprojects/highrise/277683076_455472753038333_8839849004186485525_n.jpg",
                    width: 2048,
                    height: 1031
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075871/portlandprojects/highrise/277420442_725631848780916_2599115097625770832_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075871/portlandprojects/highrise/277420442_725631848780916_2599115097625770832_n.jpg",
                    width: 1284,
                    height: 1712
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075871/portlandprojects/highrise/277507494_1361018211038986_7446520665348985583_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075871/portlandprojects/highrise/277507494_1361018211038986_7446520665348985583_n.jpg",
                    width: 1284,
                    height: 963
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075871/portlandprojects/highrise/277592034_1168337097272026_1222439973150158397_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075871/portlandprojects/highrise/277592034_1168337097272026_1222439973150158397_n.jpg",
                    width: 1284,
                    height: 963
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075870/portlandprojects/highrise/277541448_312908944262976_5135436575676025942_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075870/portlandprojects/highrise/277541448_312908944262976_5135436575676025942_n.jpg",
                    width: 1031,
                    height: 2048
                },
                {
                    largeURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/v1649075869/portlandprojects/highrise/277683814_288956719927617_2838468124276223469_n.jpg",
                    thumbnailURL: "https://res.cloudinary.com/dmg7nc60x/image/upload/c_thumb,w_800/v1649075869/portlandprojects/highrise/277683814_288956719927617_2838468124276223469_n.jpg",
                    width: 1284,
                    height: 963
                }
            ]}/>
    );
};

export default HighRise;