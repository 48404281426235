import styled from "styled-components";

export const ContactUsCardInfo = styled.div`
  background-color: #e09082;
  background-image: linear-gradient(315deg, #754b44 0%, #93805d 74%);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 10px;
  padding: 2rem;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  &::after {
    content: '';
    animation: floatingSmall 10s linear infinite;
    position: absolute;
    bottom: 4rem;
    right: 4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 100px;
    background-color: rgba(91, 59, 54, 0.8);
  }

  &::before {
    content: '';
    animation: floatingLarge 12s linear infinite;
    position: absolute;
    bottom: -8rem;
    right: -8rem;
    width: 16rem;
    height: 16rem;
    border-radius: 100%;
    background-color: rgba(178, 143, 80, 0.5);
  }

  @keyframes floatingSmall {
    0% {
      transform: rotate(0deg) translateX(6px);
    }
    50% {
      transform: rotate(360deg) translateX(6px);
    }
    100% {
      transform: rotate(720deg) translateX(6px);
    }
  }

  @keyframes floatingLarge {
    0% {
      transform: rotate(0deg) translateX(8px);
    }
    50% {
      transform: rotate(-360deg) translateX(8px);
    }
    100% {
      transform: rotate(-720deg) translateX(8px);
    }
  };
`

export const ContactUsCardForm = styled.div`
  background: #ffffff;
  border-radius: 10px;
`

export const ContactUsPhone = styled.a`
  color: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 2rem 0;
  text-decoration: none;
`

export const ContactUsPhoneLink = styled.span`
  color: #fff;
  display: block;
  margin-left: 0.5rem;
`

export const ContactUsEmail = styled.a`
  position: relative;
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
`

export const ContactUsEmailLink = styled.span`
  color: #fff;
  display: block;
  margin-left: 0.5rem;
`