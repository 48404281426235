import React, {useEffect} from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/dist/photoswipe.css';
import './SimpleGallery.scss';
import lozad from "lozad";
import {Body, Container, Heading, TextWrapper, TopLine, Wrapper} from "../PageElements";

const SimpleGallery = ({
                           topLine,
                           headLine,
                           description,
                           lightText,
                           darkText,
                           lightBg,
                           ...props
                       }) => {
    const {observe} = lozad();
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + props.galleryID,
            children: 'a',
            pswpModule: () => import('photoswipe'),
            bgOpacity: 1,
        });
        lightbox.init();
        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, [props.galleryID]);

    useEffect(() => {
        observe();
    }, [observe]);

    return (
        <Container lightBg={lightBg} id={props.galleryID}>
            <Wrapper>
                <TextWrapper className="pb-6">
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headLine}</Heading>
                    <Body darkText={darkText} dangerouslySetInnerHTML={{__html: `${description}`}}/>
                </TextWrapper>
                <div className="pswp-gallery" id={props.galleryID}>
                    {props.images.map((image, index) => (
                        <a key={props.galleryID + '-' + index} href={image.largeURL}
                           data-pswp-width={image.width}
                           data-pswp-height={image.height}
                           target="_blank"
                           rel="noreferrer">
                            <img className="lozad" data-src={image.thumbnailURL} alt=""/>
                        </a>
                    ))}
                </div>
            </Wrapper>
        </Container>);
}
export default SimpleGallery
